






























































import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
  props: {
    data: {
      required: true,
      type: Array,
    },
  },
  setup(props) {
    const apiData = props.data;

    return {
      title: 'รายชื่อบริษัทที่เป็นกรรมการ',
      apiData,
    };
  },
});
